import { Alpine } from '../../vendor/livewire/livewire/dist/livewire.esm';

Alpine.store('radioBar', {
  playing: false,
  clickPlayHint: false,
  clickPlayHintTimeout: -1,
  volume: 0.5,

  init() {
    if (import.meta.env.PROD) {
      // noinspection JSIgnoredPromiseFromCall
      this.tryStartPlayer();
    }
  },

  /**
   * @returns {HTMLAudioElement}
   */
  getPlayer() {
    // Note: we're getting the player every time again because on website update, a new element is created.
    return document.getElementById('radio-player');
  },

  async tryStartPlayer() {
    this.playing = true;
    this.clickPlayHint = false;
    clearTimeout(this.clickPlayHintTimeout);

    let player = this.getPlayer();
    player.src = 'https://azura.habbofeeling.nl/listen/habbofeeling/radio.mp3';
    player.volume = 0;

    try {
      await player.play();
      this.playerPlaying();
    } catch (e) {
      this.playerError();
      throw e;
    }
  },

  stopPlayer() {
    let player = this.getPlayer();
    this.playing = false;
    player.pause();
    player.src = '';
  },

  changeVolume(volume) {
    this.volume = volume;
    this.getPlayer().volume = volume;
  },

  displayPlayHint() {
    this.clickPlayHint = true;
    this.clickPlayHintTimeout = setTimeout(() => {
      this.clickPlayHint = false;
    }, 30000);
  },

  playerError() {
    this.stopPlayer();
    this.displayPlayHint();
  },

  playerPlaying() {
    // Fade in player, to not startle the user.
    let volume = 0;
    const fadeDuration = 1500;
    const fadeStep = 50;
    const fadeInterval = setInterval(() => {
      if (volume < this.volume) {
        volume += this.volume / (fadeDuration / fadeStep);
        this.getPlayer().volume = Math.min(volume, this.volume);
      } else {
        clearInterval(fadeInterval);
      }
    }, fadeStep);
  },
});
