import lodash from 'lodash';
import { DateTime, Settings } from 'luxon';
import { Alpine, Livewire } from '../../vendor/livewire/livewire/dist/livewire.esm';
import axios from 'axios';
import Pusher from 'pusher-js';
import * as Popper from '@popperjs/core';
import * as bootstrap from 'bootstrap';
import Swiper from 'swiper/bundle';
import jquery from 'jquery';
import select2 from 'select2';

window._ = lodash;
window.$ = window.jQuery = jquery;
window.axios = axios;
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.Pusher = Pusher;
window.Popper = Popper;
window.bootstrap = bootstrap;
window.Swiper = Swiper;
select2({
    theme: 'bootstrap-5',
});

Settings.defaultLocale = 'nl';
window.DateTime = DateTime;

Alpine.store('time', {
    init() {
        setInterval(() => {
            this.now = DateTime.now();
        }, 1000);
    },

    now: DateTime.now(),
});

Alpine.directive('clipboard', (el, { expression }) => {
    el.addEventListener('click', () => {
        navigator.clipboard.writeText(expression ?? el.textContent);
        window.toast('info', 'Tekst gekopieerd naar klembord!');
    });
});

import './player';
import './alpine-easymde.js';

Livewire.start();

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allow your team to quickly build robust real-time web applications.
 */

import './echo';
